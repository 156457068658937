import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop.js";
import Navbar from "./components/Navbar.js";
import NavMenu from "./components/NavMenu.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Alert, Snackbar } from "@mui/material";
import "./assets/font/iconfont.css";
import { Helmet } from "react-helmet"; // Import Helmet
const Home = lazy(() => import("./views/home.js"));
const Details = lazy(() => import("./views/details.js"));
const Brand = lazy(() => import("./views/brand.js"));
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: "linear-gradient(315deg, #F5227F 0%, #FF65AA 100%)",
          borderRadius: "16px",
          "&:hover": {
            background: "linear-gradient(315deg, #F5227F 0%, #FF65AA 100%)",
            boxShadow: "0px 4px 16px 0px rgba(255,163,205,0.7)",
          },
        },
      },
    },
  },
});

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>BeepCrypto - YOUR WEB3 CREATIVE MARKETING AGENCY</title>
          <meta
            name="description"
            content="Founded in 2019, BeepCrypto stands as a leading creative marketing agency in Mainland China and Hong Kong. Driven by a passionate community and a research-oriented approach, we specialize in connecting cultures and bridging the West and East. Our expertise encompasses project consultation, operational support, and comprehensive marketing services. We are dedicated to crafting tailored go-to-market strategies that nurture project development and growth in the dynamic world of Web3 and cryptocurrency."
          />

          <meta
            name="keywords"
            content="BeepCrypto, beep crypto, Web3, blockchain marketing, cryptocurrency marketing"
          />
        </Helmet>
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={() => setSnackOpen(false)}
        >
          <Alert
            onClose={() => setSnackOpen(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Request Sent Successfully!
          </Alert>
        </Snackbar>
        <NavMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
        <ScrollToTop />
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route
                path="/"
                element={<Home isOpen={isOpen} setSnackOpen={setSnackOpen} />}
              />
              <Route path="/details/:id" element={<Details />} />
              <Route path="/brand" element={<Brand />} />
            </Routes>
          </Suspense>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
