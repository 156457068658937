import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../assets/icons/logo.png";
import Lottie from "react-lottie";
import data from "../assets/lottie/test.json";
import CustomLottie from "./lottie";
import HamburgerMenu from "./Hamburger";

export default function Navbar({ isOpen, setIsOpen }) {
  return (
    <Box sx={{ flexGrow: 1, position: "relative" }}>
      <AppBar
        position="fixed"
        color="default"
        sx={{
          backgroundColor: "#fff",
          height: "72px",
          justifyContent: "center",
          boxShadow: "0px 4px 8px 0px rgba(159,158,159,0.1)",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pr: "0 !important",
          }}
        >
          <Box
            component={"img"}
            src={Logo}
            onClick={() => window.open("/", "_self")}
            sx={{ width: "187px", height: "32px", cursor: "pointer" }}
          ></Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          zIndex: "100000",
          position: "fixed",
          top: 0,
          right: 0,
          width: "104px",
          height: "72px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: isOpen && "#F4F4F4",
          "&:hover": {
            background: "#F4F4F4",
          },
        }}
      >
        <HamburgerMenu isOpen={isOpen} setOpen={setIsOpen} />
      </Box>
    </Box>
  );
}
