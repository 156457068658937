import React from "react";
import Lottie from "react-lottie";
function CustomLottie({
  data,
  play = true,
  direction = 0,
  height = "100%",
  width = "100%",
  defaultBg = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  },
  isStopped = false,
  handleAnimationComplete = () => {},
}) {
  return (
    <Lottie
      options={defaultBg}
      height={height}
      width={width}
      isStopped={!play}
      direction={direction}
      onComplete={handleAnimationComplete}
    />
  );
}

export default CustomLottie;
