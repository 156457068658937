import { Box, Tooltip, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Logo from "../assets/icons/logo-white.png";
import "./css/text.css";

import LightTooltip from "./LightToolTip";
import wechat from "../assets/qr/wechat.png";
import { isMobile } from "../util/env";
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,

//     boxShadow: "0px 4px 16px 0px rgba(159,158,159,0.2)",
//     borderRadius: "12px",
//     fontSize: "16px",
//     fontFamily: "HelveticaNeue-Bold, HelveticaNeue",
//     fontWeight: "bold",
//     color: "#252325",
//     lineHeight: "24px",
//   },
// }));
function NavMenu({ isOpen, setIsOpen }) {
  const [currentTab, setCurrentTab] = useState(0);
  const leftProps = useSpring({
    transform: isOpen ? "translateY(0%)" : "translateY(-100%)",
    opacity: isOpen ? 1 : 0,

    height: isOpen ? (isMobile() ? "30vh" : "100vh") : "0",
    width: isMobile() ? "" : "388px",
    background: "linear-gradient(315deg, #F5227F 0%, #FF65AA 100%)",
  });
  const rightProps = useSpring({
    transform: isOpen ? "translateY(0%)" : "translateY(100%)",
    opacity: isOpen ? 1 : 0,
    height: isOpen ? (isMobile() ? "70vh" : "100vh") : "0",
    width: isMobile() ? "" : "1052px",

    background: "#FFFFFF",
  });
  const handleClick = (id) => {
    console.log("Button clicked with ID:", id);
    const formattedId = id.replace(/\s+/g, "-");
    const el = document.getElementById(formattedId);
    console.log("Element fetched: ", el);

    if (el) {
      // Calculate the target position: element's top offset + 72px
      let targetPosition =
        el.getBoundingClientRect().top + window.pageYOffset - 72;
      if (id === "Contact Us")
        targetPosition =
          el.getBoundingClientRect().top + window.pageYOffset + 248;
      if (id === "About Us")
        targetPosition = el.getBoundingClientRect().top + window.pageYOffset;
      if (id === "How Can We Assist You")
        targetPosition =
          el.getBoundingClientRect().top + window.pageYOffset - 160;
      // Smoothly scroll to the calculated position
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }

    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: "10000",
        top: 0,
        display: isOpen ? "block" : "none",
        height: { xs: "100vh", md: "100%" },
      }}
    >
      <Box
        sx={{ display: "flex", flexFlow: { xs: "column-reverse", md: "row" } }}
      >
        <animated.div style={leftProps}>
          <Box
            sx={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-between",
              height: { md: "100%" },
              pl: { xs: "16px", md: "80px" },
              pr: { xs: "16px", md: "54px" },
            }}
          >
            <Box
              component={"img"}
              src={Logo}
              sx={{ width: "64px", height: "64px", mt: "40px" }}
            ></Box>
            <Box>
              <Typography
                sx={{
                  fontSize: { md: "24px" },
                  fontFamily: "HelveticaNeue-Bold, HelveticaNeue",
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  lineHeight: { md: "32px" },
                  textAlign: "left",
                }}
              >
                YOUR WEB3 CREATIVE MARKETING AGENCY
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "16px" },
                  fontFamily: "HelveticaNeue-Medium, HelveticaNeue",
                  fontWeight: 500,
                  color: "rgba(255,255,255,0.7)",
                  lineHeight: { md: "24px" },
                  textAlign: "left",
                  mt: "8px",
                  maxWidth: "200px",
                }}
              >
                Connecting Cultures, Bridging West and East.
              </Typography>
              <Box
                sx={{
                  mt: { xs: "24px", md: "80px" },

                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Box sx={{ display: "flex", gap: "16px" }}>
                  <LightTooltip title="Twitter" sx={{ zIndex: "10000" }}>
                    <Box
                      onClick={() =>
                        window.open("https://twitter.com/beep_crypto")
                      }
                      className="icon iconfont icon-Twitter2"
                      sx={{
                        fontSize: "20px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    ></Box>
                  </LightTooltip>
                  <LightTooltip
                    title={
                      <img
                        src={wechat}
                        alt="Description"
                        style={{ width: "160px", height: "160px" }}
                      />
                    }
                    sx={{ zIndex: "10000" }}
                  >
                    <Box
                      className="icon iconfont icon-weixin"
                      sx={{
                        fontSize: "20px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    ></Box>
                  </LightTooltip>
                  <LightTooltip title="Weibo" sx={{ zIndex: "10000" }}>
                    <Box
                      onClick={() =>
                        window.open("https://weibo.com/u/7268014639")
                      }
                      className="icon iconfont icon-weibo"
                      sx={{
                        fontSize: "20px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    ></Box>
                  </LightTooltip>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontFamily: "HelveticaNeue-Medium, HelveticaNeue",
                  fontWeight: 500,
                  color: "rgba(255,255,255,0.4)",
                  lineHeight: "24px",
                  textAlign: "left",
                  mt: "8px",
                  mb: "62px",
                }}
              >
                © Beep Crypto 2023
              </Typography>
            </Box>
          </Box>
        </animated.div>
        <animated.div style={rightProps}>
          <Box
            sx={{
              mt: "112px",
              ml: { xs: "16px", md: "64px" },
              pr: { xs: "16px", md: "80px" },
            }}
          >
            {[
              "Home",
              "About Us",
              "Offline Events and Curation",
              "How Can We Assist You",
              "Contact Us",
            ].map((item, i) => {
              return (
                <Box
                  key={i}
                  sx={{ position: "relative", width: "max-content" }}
                  onClick={() => handleClick(item)}
                >
                  <Typography
                    className={`tabText ${
                      i === currentTab ? "underline-effect-active" : ""
                    }`}
                    onClick={() => setCurrentTab(i)}
                    key={i}
                    sx={{
                      display: "inline-block",
                      cursor: "pointer",
                      textAlign: "left",
                      fontSize: { xs: "24px", md: "64px" },
                      fontFamily: "HelveticaNeue-Medium, HelveticaNeue",
                      fontWeight: 500,

                      lineHeight: { md: "80px" },
                      backgroundPosition: i === currentTab && 0,
                      mb: "24px",
                      backgroundImage:
                        i === currentTab &&
                        "linear-gradient(90deg, #252325ff 50%, #666566ff 50%)",
                      textDecorationColor: "#252325ff",
                      textDecoration: i === currentTab && "underline",
                      "&:hover": {
                        transition: "1s ",
                        textDecoration: "underline",
                        textDecorationColor: "#252325ff",
                      },
                    }}
                  >
                    {item}
                    <Typography
                      sx={{
                        position: "absolute",
                        display: "inline-block",
                        top: "10px",
                        right: "-20px",
                        fontSize: "16px",
                        fontFamily: "HelveticaNeue-Medium, HelveticaNeue",
                        fontWeight: 500,
                        color: "#A8A7A8",
                        lineHeight: "24px",
                      }}
                    >{`0${i + 1}`}</Typography>
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </animated.div>
      </Box>
    </Box>
  );
}

export default NavMenu;
